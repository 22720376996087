/* Spinner.css */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
}

.bubble {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    animation: spin 2s linear infinite;
}

.bubble1 {
    background-color: #40D7FF;
    animation-delay: 0s;
}

.bubble2 {
    background-color: #5479DC;
    animation-delay: 0.5s;
}

.bubble3 {
    background-color: #5BEBAF;
    animation-delay: 1s;
}

@keyframes spin {
    0% { transform: translateY(0); }
    25% { transform: translate(20px, 20px); }
    50% { transform: translateY(40px); }
    75% { transform: translate(-20px, 20px); }
    100% { transform: translateY(0); }
}
